<template>
	<div class="business-address-create">
		<w-navTab titleText="编辑地址"></w-navTab>
		<div class="map-container"><div id="map"></div></div>
		<div class="address-container">
			<div class="address-input-item">
				<input
					type="text"
					class="address-input"
					v-model="name"
					placeholder="请输入店铺名称"
				/>
			</div>
			<div class="address-input-item">
				<input
					type="number"
					class="address-input"
					v-model="telephone"
					placeholder="请输入店铺电话"
				/>
			</div>
			<div class="address-input-item">
				<input
					type="text"
					class="address-input"
					v-model="address"
					placeholder="请输入店铺地址"
				/>
			</div>
			<div class="address-input-tips">您可拖地图选择店铺位置</div>
			<div class="address-input-button">
				<van-button round block type="info" @click="onSubmit">保存</van-button>
			</div>
		</div>
	</div>
</template>

<script>
import UtilsVendor from '@/vendor/utils';
import BusinessApi from '@/api/business';
import VantVendor from '@/vendor/vant';
import ValidatorVendor from '@/vendor/validator';

export default {
	name: 'BusinessAddressUpdate',
	data() {
		return {
			name: '',
			telephone: '',
			address: '',
			latitude: 0,
			longitude: 0,
			map: null,
			addressId: ''
		};
	},
	created() {
		this.addressId = this.$route.params.id;

		BusinessApi.getAddressDetail({
			address_id: this.addressId
		})
			.then(result => {
				this.name = result.data.name;
				this.address = result.data.address;
				this.telephone = result.data.telephone;
				this.latitude = result.data.latitude;
				this.longitude = result.data.longitude;

				this.createMap(this.latitude, this.longitude);
			})
			.catch(error => {
				console.log(error);
			});

		this.validator = new ValidatorVendor({
			rules: {
				name: {
					required: true
				},
				address: {
					required: true
				},
				telephone: {
					required: true,
					mobile: true
				}
			},
			messages: {
				name: {
					required: '店铺名称不能为空'
				},
				telephone: {
					mobile: '手机号格式错误',
					required: '手机号不能为空'
				},
				address: {
					required: '店铺地址不能为空'
				}
			}
		});
	},
	methods: {
		onSubmit() {
			let params = {
				name: this.name,
				address: this.address,
				telephone: this.telephone,
				latitude: this.latitude,
				longitude: this.longitude,
				address_id: this.addressId
			};

			if (!this.validator.checkData(params)) {
				return;
			}

			BusinessApi.editAddress(params)
				.then(result => {
					VantVendor.Dialog({ title: '提示', message: '修改成功' }).then(() =>
						this.$router.back()
					);
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		createMap(latitude, longitude) {
			let center = new TMap.LatLng(latitude, longitude);

			this.map = new TMap.Map('map', {
				rotation: 0,
				pitch: 0,
				zoom: 15,
				center: center
			});

			let geocoder = new TMap.service.Geocoder();

			let marker = new TMap.MultiMarker({
				map: this.map,
				styles: {
					marker: new TMap.MarkerStyle({
						width: 20,
						height: 30,
						anchor: {
							x: 10,
							y: 30
						}
					})
				},
				geometries: [
					{
						position: center,
						id: 'marker'
					}
				]
			});

			let getLocation = UtilsVendor.debounce(center => {
				geocoder
					.getAddress({
						location: center
					})
					.then(location => {
						this.latitude = location.result.location.lat;
						this.longitude = location.result.location.lng;
						this.address = location.result.address;
					});
			}, 100);

			this.map.on('pan', () => {
				let center = this.map.getCenter();
				marker.updateGeometries({
					id: 'marker',
					position: center
				});
				getLocation(center);
			});
		}
	}
};
</script>

<style scoped lang="less">
.business-address-create {
	height: 100vh;
	position: relative;

	.map-container {
		position: absolute;
		top: 47px;
		left: 0;
		width: 100%;
		height: calc(100vh - 290px);

		/deep/#map {
			width: 100%;
			height: 100%;

			.logo-text,
			a {
				display: none;
			}
		}
	}

	.address-container {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 290px;
		background-color: #fff;
		padding: 15px;
		box-sizing: border-box;

		.address-input-tips {
			font-size: 14px;
			color: #999;
			margin-bottom: 20px;
			text-align: center;
		}

		.address-input-item {
			position: relative;
			margin-bottom: 15px;

			.address-input {
				display: block;
				width: 100%;
				border: none;
				background-color: #f5f5f5;
				padding: 10px;
				line-height: 20px;
				border-radius: 6px;
				font-size: 16px;
				box-sizing: border-box;
			}
		}

		.address-input-button {
			.van-button {
				font-size: 18px;
			}
		}
	}
}
</style>
